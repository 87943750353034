import request from '../utils/request' // 引入封装得axios


// 登录时获取商品列表
export function getVerifyKey() {
    return request({
        url: `/speedinOffical/verification.do`,
        method: 'POST'
    })
}
// 注册
export function regist(params) {
    return request({
        url: `/speedinOfficial/user/regist.do`,
        method: 'POST',
        data: params
    })
}
// 注册
export function loginRequest(params) {
    return request({
        url: `/speedinOfficial/user/login.do`,
        method: 'POST',
        data: params
    })
}