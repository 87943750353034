// const protocol = window.location.protocol
const localBaseUrl = "http://devws.hotpotcn.net";
const devBaseUrl = "http://devws.hotpotcn.net";
const uatBaseUrl = "https://uatws.hotpotcn.net";
const prodBaseUrl = "https://ws.hotpotcn.net";

let baseUrl = "";
const env = process.env.NODE_ENV;
if (env === "production") {
    if (location.host.startsWith('uat')) {
        baseUrl = uatBaseUrl;
    } else if (location.host.startsWith('dev')) {
        baseUrl = devBaseUrl;
    } else {
        baseUrl = prodBaseUrl;
    }
} else {
    baseUrl = localBaseUrl;
}
export default {
    baseUrl: baseUrl,
};