import { createStore } from 'vuex'
import { loginRequest } from '../api/login'
/**
 * 创建仓库和导出
 */
export default createStore({
    state: {
        userInfo: null
    },
    mutations: {
        setUserInfo(state, data) {
            state.userInfo = data
        }
    },
    actions: {
        loginRequest({ commit }, params) {
            return new Promise((resolve, reject) => {
                loginRequest(params).then(res => {
                    if (res.status == 1) {
                        commit('setUserInfo', res.fields)
                        resolve(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })

        }
    }
})