<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex';
let store = useStore();
function getLocalInfo() {
  let userInfo = localStorage.getItem('userInfo');
  if (userInfo) {
    store.commit('setUserInfo', JSON.parse(userInfo))
  }
}
onMounted(() => {
  getLocalInfo()
})
</script>

<template>
  <router-view></router-view>
</template>

<style scoped></style>
